<div class="">

    <div class="row w-100" style="border-bottom: 5px solid #1830a3">
        <div class="col">
            <img src="../../../assets/images/ares-userlogo.png" style="height:50px;margin: 5px;">
        </div>
        <div class="col">
            <div class="wrapper">
                <h4>Bulk Order</h4>
            </div>
        </div>
        <div class="col"></div>
    </div>

    <div class="col-md-12">
        <div class="square-box">
            <div class="inner-box"> <span class="dot"></span> Order is Placed</div>
            <div class="inner-box"> <span class="dot"></span> Artwork Approved</div>
            <div class="inner-box"> <span class="dot"></span> Purchased</div>
            <div class="inner-box"> <span class="dot"></span> Order Received</div>
            <div class="inner-box"> <span class="dot"></span> Production Started</div>
            <div class="inner-box"> <span class="dot"></span> Shipment</div>
            <div class="inner-box"> <span class="dot"></span> Payment</div>
        </div>
    </div>

    <div class="row row-class">
        <div class="col-xl-4 col-md-6 col-sm-12">

            <div class="row"
                style="background: #ffffff;; border: solid; border-color: #0053a6; border-radius: 9px;margin-bottom: 14px; margin-top: 10px;height: 8rem;">
                <div class="accordion" style="width: 100%;">
                    <div class="accordion-header">
                        <div class="rush-container" style="background: #0053a6;">
                            <tr>
                                <td>
                                    <h5 style="color:#fdfdfd">In-Hands Date</h5>
                                </td>
                            </tr>
                        </div>
                    </div>
                    <div class="accordion-content">
                        <div class="container2">
                            <div class="row2 m-0">
                            </div>

                            <div class="container2">
                                <div class="row2 justify-content-center">
                                    <div class="col-md-12 text-center">
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div class="row2 justify-content-center">
                                    <div class="col-md-12 text-center">
                                        <p style="font-size: 27px; color: #0053a6;"><b>{{getDate()}}</b></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row"
                style="background: #fff; border-radius: 9px; margin-bottom: 14px; margin-bottom: 8px; margin-top: 10px;">
                <div class="accordion">
                    <div class="accordion-header">
                        <div class="rush-container">
                            <div class="rush-left-side">
                                <tr>
                                    <td><span class="triangle" (click)="rotateArrow = !rotateArrow; toggleOrder()"
                                            [style.transform]="rotateArrow ? 'rotate(180deg)' : ''"></span></td>
                                    <td>
                                        <h5 (click)="rotateArrow = !rotateArrow; toggleOrder()">
                                            Order#{{bulkOrer?.orderId ? bulkOrer.orderId :
                                            bulkOrer?.cartItemsInfo[0]?.bulkOrderShoppingCartMasterId }}</h5>
                                    </td>

                                </tr>


                            </div>
                        </div>
                    </div>
                    <div class="accordion-content" *ngIf="orderExpanded">
                        <div class="container">
                            <div class="row m-0">
                            </div>
                            <br />
                            <br />

                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>Web Order {{bulkOrer?.createdDate | date : "MM/dd/yyyy hh:mm a"}}.</p>
                                        <p>Sales Rep : {{bulkOrer?.salesRep}}.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p><b>Customer Information</b></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="address">
                                            <div class="circle">
                                                <div class="inner-circle">
                                                    {{getInitials(this.bulkOrer?.user?.firstName,this.bulkOrer?.user?.lastName)}}
                                                </div>
                                            </div>
                                            <div class="address-info">
                                                <p>{{this.bulkOrer?.billingAddress?.billingAddress}}</p>
                                                <p>{{this.bulkOrer?.billingAddress?.billingAddress1}}</p>

                                                <p>{{this.bulkOrer?.billingAddress?.billingCity}}</p>
                                                <p>{{this.bulkOrer?.billingAddress?.billingState}}</p>
                                                <p>{{this.bulkOrer?.billingAddress?.billingCountry}}</p>
                                                <p>{{this.bulkOrer?.billingAddress?.billingZipCode}}</p>
                                                <p>{{this.bulkOrer?.billingAddress?.email}}</p>
                                                <p>{{this.bulkOrer?.billingAddress?.phoneNumber}}</p>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="address">
                                            <div class="circle">
                                                <div class="inner-circle">
                                                    {{getInitials(this.bulkOrer?.user?.firstName,this.bulkOrer?.user?.lastName)}}
                                                </div>
                                            </div>
                                            <div class="address-info">
                                                <p>{{this.bulkOrer?.shippingAddress?.shippingAddress}}</p>
                                                <p>{{this.bulkOrer?.shippingAddress?.shippingAddress1}}</p>

                                                <p>{{this.bulkOrer?.shippingAddress?.shippingCity}}</p>
                                                <p>{{this.bulkOrer?.shippingAddress?.shippingState}}</p>
                                                <p>{{this.bulkOrer?.shippingAddress?.shippingCountry}}</p>
                                                <p>{{this.bulkOrer?.shippingAddress?.shippingZipCode}}</p>
                                                <p>{{this.bulkOrer?.shippingAddress?.email}}</p>
                                                <p>{{this.bulkOrer?.shippingAddress?.phoneNumber}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row"
                style="background: #fff; border-radius: 9px; margin-bottom: 14px; margin-bottom: 8px; margin-top: 10px;">
                <div class="accordion">
                    <div class="accordion-header">
                        <div class="rush-container">
                            <div class="rush-left-side">
                                <tr>
                                    <td><span class="triangle"
                                            (click)="rotateTimingArrow = !rotateTimingArrow; toggleOrderTiming()"
                                            [style.transform]="rotateTimingArrow ? 'rotate(180deg)' : ''"></span></td>
                                    <td>
                                        <h5 (click)="rotateTimingArrow = !rotateTimingArrow; toggleOrderTiming()">Order
                                            Timing</h5>
                                    </td>
                                    <td>
                                        <div class="inner-box"> <span class="dot"></span> Order At-Risk</div>
                                    </td>
                                </tr>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-content" *ngIf="orderTimingExpanded">
                        <div class="container">
                            <div class="row m-0">
                            </div>
                            <br />
                            <br />

                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12" style="  margin-bottom: -10px;  ">
                                        <p>Regular Ship Date</p>
                                        <p>Rush Order Date</p>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="address">

                                            <div class="address-info" style="width: 12rem;">
                                                <p>Turnaround Time</p>
                                                <p>Committed Ship Date</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="address">

                                            <div class="address-info">
                                                <p>7 Business Days</p>
                                                <p>-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row"
                style="background: #fff; border-radius: 9px; margin-bottom: 14px; margin-bottom: 8px; margin-top: 10px;">
                <div class="accordion" style="width: 100%;">
                    <div class="accordion-header">
                        <div class="rush-container">
                            <div class="rush-left-side">
                                <tr>
                                    <td><span class="triangle"
                                            (click)="rotateOrderSummaryArrow = !rotateOrderSummaryArrow; toggleOrderSummary()"
                                            [style.transform]="rotateOrderSummaryArrow ? 'rotate(180deg)' : ''"></span>
                                    </td>
                                    <td>
                                        <h5
                                            (click)="rotateOrderSummaryArrow = !rotateOrderSummaryArrow; toggleOrderSummary()">
                                            Order Summary </h5>
                                    </td>
                                </tr>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-content" *ngIf="summaryExpanded">
                        <br />
                        <br />
                        <div *ngIf="isAllProductsLoaded">
                            <div class="container">
                                <table class="prod-info">
                                    <tr class="forLabel">
                                        <th>Subtotal</th>
                                        <td>
                                            <span class="highlighted-text">
                                                <label>${{subTotalwithoutDiscountOrCoupon()| number : "1.2-2"}}</label>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="forLabel">
                                        <th style="color: black;">Discount </th>
                                        <td>
                                            <span style="font-weight: bold; font-size: 20px;">
                                                -${{getTotalDiscountAmount()| number : "1.2-2"}}
                                            </span>
                                        </td>

                                    </tr>
                                    <!-- <tr class="forLabel">
                                        <th>Subtotal After Discount</th>
                                        <td>
                                            <span class="highlighted-text">
                                                <label>${{getSubTotal()| number : "1.2-2"}}</label>
                                            </span>
                                        </td>
                                    </tr> -->
                                    <tr class="forLabel">
                                        <th>Coupon Discount</th>
                                        <td>
                                            <span class="highlighted-text">
                                                -${{ this.bulkOrer?.totalDiscount ? this.bulkOrer.totalDiscount :0 |
                                                number : "1.2-2" }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="forLabel">
                                        <th>Shipping</th>
                                        <td>
                                            <span class="highlighted-text">
                                                ${{ this.bulkOrer?.shippingAmount ? this.bulkOrer.shippingAmount : 0 |
                                                number :"1.2-2" }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="forLabel">
                                        <th>Rush fee</th>
                                        <td>
                                            <span class="highlighted-text">
                                                ${{ this.bulkOrer?.rushFee ? this.bulkOrer.rushFee : 0 | number :
                                                "1.2-2" }}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="forLabel">
                                        <th>Tax</th>
                                        <td>
                                            <span class="highlighted-text">
                                                ${{ this.bulkOrer?.taxAmount ? this.bulkOrer.taxAmount : 0 | number :
                                                "1.2-2" }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="forLabel">
                                        <th>Total</th>
                                        <td>
                                            <span class="highlighted-text">
                                                <!-- ${{ bulkOrer.subTotal + bulkOrer.shippingAmount + bulkOrer.taxAmount | number : "1.2-2" }} -->
                                                ${{ bulkOrer?.totalAmount| number : "1.2-2" }}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="spinner-border spinner-border-sm" role="status" *ngIf="!isAllProductsLoaded">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>

        </div>

        <div class="col-xl-8 col-md-6 col-sm-12">
            <div style="background: #fff; border-radius: 18px;  margin-bottom: 8px; margin-top: 10px;">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 style="margin-top: 10px; margin-left: 20px;">Mockup Summary</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9 col-sm-12">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <div class="prod-img">
                                <div class="img-container"
                                    style="width: 340px; height: 300px; background-size: cover; background-position: center;"
                                    [ngStyle]="{ 'background-image': 'url(' + getSelectedProdImage() + ')' || 'url(noImage.jpg)' }">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-sm-12">
                        <div *ngIf="getSelectedProdImage2()" style="display: flex;  align-items: center;">
                            <div class="prod-img">
                                <div class="img-container"
                                    style="width: 155px; height: 150px; background-size: cover; background-position: center;border: 2px solid black;"
                                    [ngStyle]="{ 'background-image': 'url(' + getSelectedProdImage2() + ')' || 'url(noImage.jpg)' }">
                                </div>
                            </div>
                        </div>


                        <div class="" style="  margin-top: 1rem;" *ngIf="getCurrentProductUsedColors()?.length>0">
                            <div class="main-container table-scroll-container ">
                                <div class="color-list">
                                    <div class="color-item"
                                        *ngFor="let item of getCurrentProductUsedColors();let i=index">
                                        <div class="color-square" [style.background-color]="item.hexCode"></div>
                                        <div class="color-name">{{item?.name}}</div>
                                    </div>

                                    <div class="color-item"
                                        *ngFor="let item of getCurrentProductUsedFonts();let i=index">
                                        <div class="color-name">{{item}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="container">
                    <div class="row">
                        <div class="col-md-9"
                            style="text-align: -webkit-center;  margin-left: -1rem;  margin-top: 1rem;">
                            <tr>
                                <td>
                                    <div class="inner-box" (click)="PreviousClicked()"><span>&#8592;</span> Prev</div>
                                </td>
                                <td>
                                    <div class="inner-box" style="margin-left: 0rem;">{{imageIndex}} of {{imageLength}}
                                        Designs</div>
                                </td>
                                <td>
                                    <div class="inner-box" style="margin-left: 0rem;" (click)="NextClicked()"> Next
                                        <span>&#8594;</span>
                                    </div>
                                </td>
                            </tr>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-12 col-sm-6"
                style="background: #fff; border-radius: 18px;  margin-bottom: 8px; margin-top: 10px; width: 100%; max-width: 1200px; margin: 0 auto; padding: 20px;">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h5 style="margin-top: 10px; margin-left: 20px;">Products</h5>
                            <tr>
                                <td>
                                </td>
                            </tr>
                        </div>
                    </div>
                </div>
                <form [formGroup]="productForm" class="prod-form">
                    <div class="row">
                        <div formArrayName="products" class="col-md-12 table-scroll-container products">
                            <div *ngFor="let item of productForm.get('products')?.controls; let i=index;"
                                [formGroupName]="i" (click)="selectedProdIndex=i;selectedIndex=0;getSelectedProdImage()"
                                style="margin-top: 27px;margin-bottom: -2rem; border-top: 1px solid #e5e7eb; border-bottom: 1px solid #e5e7eb;">
                                <div id="accordion{{i}}" class="accordion">
                                    <div class="accordion-header" [id]="'heading' + i">
                                        <div class="rush-container ">
                                            <div class="rush-left-side">
                                                <tr>
                                                    <td style="border: none;" (click)="toggleAccordion(i)"> <span
                                                            class="triangle" [class.rotate]="rotateProdArrow[i]">
                                                        </span> </td>
                                                    <td style="width: 25rem;" (click)="toggleAccordion(i)">
                                                        <h5>{{item?.value.productDisplayName}}</h5>
                                                    </td>
                                                    <td>
                                                        <div class="buttons"
                                                            [ngClass]="{'single-button': approvalStatus[i] != 'approved' && approvalStatus[i] != 'noAction'}">
                                                            <button *ngIf="approvalStatus[i] !=='rejected'"
                                                                [disabled]=" isRejectedCart==true || isPaymentCompleted"
                                                                class="btn btn-success" (click)="approveClicked(i)">
                                                                &#10003;
                                                            </button>
                                                            &nbsp;
                                                            <button *ngIf="approvalStatus[i]!=='approved'"
                                                                [disabled]=" isRejectedCart==true || isPaymentCompleted"
                                                                style="border-radius: 25px;" class="btn btn-danger"
                                                                (click)="rejectClicked(i)">
                                                                &#10006;
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style="margin-left: 5rem;" *ngIf="isValidNANButton(item,i)"
                                                            class="buttons">
                                                            <button class="btn btn-success" (click)="openNANsScreen(i)"
                                                                title="View Name & Number"> View Name & Number
                                                            </button>

                                                        </div>
                                                    </td>
                                                </tr>

                                            </div>
                                        </div>

                                        <div [id]="'collapse' + i" class="collapse" [class.show]="rotateProdArrow[i]"
                                            [attr.aria-labelledby]="'heading' + i">
                                            <div class="container">
                                                <div class="row m-0">
                                                </div>
                                                <br />
                                                <br />

                                                <div class="container">
                                                    <table class="custom-table">
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Color</th>
                                                            <th>Sizes</th>
                                                            <th>Type</th>
                                                            <th>Quantity</th>
                                                            <th>Unit Price</th>
                                                            <th>Discount</th>
                                                            <th>Total</th>
                                                        </tr>
                                                        <tr
                                                            *ngFor="let prod of getFilteredProducts(item?.value?.cartItemGrids);let k=index">
                                                            <td>{{item?.value?.productDisplayName}}</td>
                                                            <td>{{item?.value?.color}}</td>
                                                            <td>{{prod?.size}}</td>
                                                            <td>{{getTypeName(item?.value)}}</td>
                                                            <td>{{prod?.quantity}}</td>
                                                            <td>{{getPerUnitPrice(i)}}</td>
                                                            <td>{{item?.value?.discount}}</td>
                                                            <td>
                                                                <span *ngIf="k==0">${{ getTotalPrice(i) ||
                                                                    item?.value?.totalPrice}}</span>
                                                                <span *ngIf="k!=0">-</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>


                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </form>

            </div>
        </div>


    </div>

    <div class="row row-class">
        <div class="row">
            <div class="col-md-12" class="float">
                <div class="d-flex justify-content-center align-items-center flex-wrap">
                    <div class="address-info" *ngIf="!isRejectedCart">
                        <button *ngIf="!isPaymentCompleted" (click)="approveOrders()"
                            style="background-color: #1830a3; color: white; border: #1830a3; border-radius: 4px; width: 12rem; height: 3rem; margin: 1rem;">
                            Approve and Pay
                        </button>
                    </div>
                    <div class="address-info" *ngIf="!isRejectedCart">
                        <button *ngIf="!isPaymentCompleted" (click)="rejectOrderClicked()"
                            style="background-color: #f44336; color: white; border: #f44336; border-radius: 4px; width: 12rem; height: 3rem; margin: 1rem;">
                            Reject Order
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>



<div class="modal fade" id="prodModal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" style="max-width: 35rem;">
        <div class="modal-content">
            <div class="modal-header">
                <label>Payment</label>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div class="page-wrapper">
                    <div class="payment-info">
                        <div class="pay-by-credit-card">
                            <div class="card-logo">
                                <!-- <img src="{{ src }}" *ngFor="let src of cardLogos" /> -->
                                <a href="https://www.braintreegateway.com/merchants/hndc4jr4yxm9ygpt/verified"
                                    target="_blank">
                                    <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
                                        style="width: 300px;" border="0" />
                                </a>
                            </div>
                            <div class="card-details mt-3">
                                <div id="dropin-container"></div>
                                <div class="w-100 text-center">
                                    <button id="payment-request-button" style="display: none"></button>
                                    <button class="btn btn-primary btn-lg m-2 mb-0" (click)="checkout()"
                                        id="pay-now-btn" [disabled]="isCheckoutDisabled()">
                                        <!-- Pay with <span id="card-brand">Card</span> -->
                                        Place Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="rejectStoreModal" role="dialog" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5>Product rejection reason</h5>
                <button type="button" class="close" (click)="cancelRejection()">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="storeRejectionForm">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Reason</mat-label>
                        <mat-select formControlName="amRejectionCodes" multiple>
                            <mat-option *ngFor="let reason of rejectionCodes"
                                [value]="reason.id">{{reason.reasonCode}}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="storeRejectionForm.get('amRejectionCodes').invalid && storeRejectionForm.get('amRejectionCodes').touched">
                            Reason is required.
                        </mat-error>
                    </mat-form-field>
                    <br>
                    <mat-form-field style="width: 100%;">
                        <mat-label>Comment</mat-label>
                        <textarea matInput formControlName="amRejectionText"></textarea>
                        <mat-error
                            *ngIf="storeRejectionForm.get('amRejectionText').invalid && storeRejectionForm.get('amRejectionText').touched">
                            Comment is required.
                        </mat-error>
                    </mat-form-field>
                    <button type="button" class="btn btn-primary" (click)="confirmRejection()">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="rejectOrderModal" role="dialog" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5>Order rejection reason</h5>
                <button type="button" class="close" (click)="cancelOrderRejection()">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="orderRejectionForm">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Reason</mat-label>
                        <mat-select formControlName="amRejectionCodes" multiple>
                            <mat-option *ngFor="let reason of rejectionCodesForQuote"
                                [value]="reason.id">{{reason.reasonCode}}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="orderRejectionForm.get('amRejectionCodes').invalid && orderRejectionForm.get('amRejectionCodes').touched">
                            Reason is required.
                        </mat-error>
                    </mat-form-field>
                    <br>
                    <mat-form-field style="width: 100%;">
                        <mat-label>Comment</mat-label>
                        <textarea matInput formControlName="amRejectionText"></textarea>
                        <mat-error
                            *ngIf="orderRejectionForm.get('amRejectionText').invalid && orderRejectionForm.get('amRejectionText').touched">
                            Comment is required.
                        </mat-error>
                    </mat-form-field>
                    <button type="button" class="btn btn-primary" (click)="rejectOrders()">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade show" id="infoModal" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5>Quote Approval</h5>
                <button type="button" class="close" (click)="closePopup()">
                    &times;
                </button>
            </div>
            <div class="modal-body">

                <p class="store-name font-weight-bold">
                    Order#{{bulkOrer?.orderId ? bulkOrer.orderId :
                    bulkOrer?.cartItemsInfo[0]?.bulkOrderShoppingCartMasterId }}
                </p>
                <div class="notes">
                    <label>Directions: </label>
                    <p>
                        Please review your quote and each product within its entirety. If you approve, please click the
                        Approve &
                        Pay. You will be directed to the
                        payment screen.
                        <br> Please note, once approved NO CHANGES CAN BE MADE.
                        <br> If changes are required, please click the red X on the product to be updated. You will be
                        prompted to select any/all reasons and leave a comment. Being as specific as possible will
                        ensure our team will make the required changes quickly and efficiently. Finally, click the
                        Reject Quote button to send the request to our team.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="nanModal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" style="max-width: 94rem;">
        <div class="modal-content">
            <div class="modal-header">
                <label>Name & Number</label>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div style="font-size: .7rem">
                    <mat-card class="commonBorder">
                        <form [formGroup]="nanForm">
                            <div formArrayName="nans">
                                <div class="scrollable-container">
                                    <div class="row" *ngFor="let item of nanForm.get('nans')?.controls; let i=index;"
                                        [formGroupName]="i">
                                        <div class="scrollable-content">
                                            <div formArrayName="teams" class="col-md-2"
                                                *ngFor="let team of item.get('teams').controls; let t=index;">
                                                <div [formGroupName]="t">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{ getTeams()[t]?.fieldLabel }}</mat-label>
                                                        <mat-select formControlName="teamId"
                                                            (selectionChange)="updateTeamId(i, t, $event.value)"
                                                            [required]="getTeams()[t]?.isMandatory">
                                                            <mat-option>{{ getTeams()[t]?.fieldInstruction
                                                                }}</mat-option>
                                                            <mat-option *ngFor="let li of getMultipleChoices(t)"
                                                                [value]="li.id">
                                                                {{ li.value }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div formArrayName="players" class="col-md-2"
                                                *ngFor="let player of item.get('players').controls; let f=index;">
                                                <div [formGroupName]="f">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{ getPlayerValidations()[f]?.fieldLabel
                                                            }}</mat-label>
                                                        <input formControlName="player"
                                                            placeholder="{{ getPlayerValidations()[f]?.fieldInstruction }}"
                                                            type="text" matInput
                                                            (keyup)="updatePlayer(i, f, $event.target.value)"
                                                            [required]="getPlayerValidations()[f]?.isMandatory" />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label> Product Name</mat-label>
                                                    <input type="text" matInput formControlName="productName"
                                                        required />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-1">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Color</mat-label>
                                                    <input disabled type="text" matInput formControlName="color"
                                                        required />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-1">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label> size </mat-label>
                                                    <mat-select formControlName="sizeId" required>
                                                        <!-- [disabled]="isDisabled2(li.sizeId)" -->
                                                        <mat-option *ngFor="let li of getSizesForNAN(modalIndex)"
                                                            [value]="li.sizeId">
                                                            {{li.size}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-2">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Name</mat-label>
                                                    <input type="name" matInput formControlName="name"
                                                        disabled="!item.value.isNameDisabled"
                                                        onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || [32].includes(event.charCode)" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-1">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label> Number</mat-label>
                                                    <input disabled="!item.value.isNumberDisabled" type="text" matInput
                                                        formControlName="number"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                                </mat-form-field>
                                            </div>

                                            <div class="col-md-1" *ngIf="showNanButtons">
                                                <button [disabled]="isRejectedCart==true || isPaymentCompleted"
                                                    (click)="addRow()" mat-icon-button>
                                                    <img style="height: 17px; width: 14px;"
                                                        src="../../../../assets/icon/plus.ico" alt="Expand">
                                                </button>
                                                <button [disabled]="isRejectedCart==true || isPaymentCompleted"
                                                    (click)="removeRow(i,item.sizeId)" mat-icon-button>
                                                    <img style="height: 17px; width: 14px; transform: rotate(45deg);"
                                                        src="../../../../assets/icon/plus.ico" alt="Expand">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>


                    </mat-card>
                    <div mat-dialog-actions align="end" *ngIf="showNanButtons">
                        <button [disabled]="isRejectedCart==true || isPaymentCompleted " mat-raised-button
                            class="text-left m-1" color="primary" (click)="saveNAN(modalIndex,false)">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="payWithPo" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header">
                <h5>Pay with Purchase Order</h5>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div class="form-container">
                    <div class="custom-form-container" *ngIf="user">
                        <form [formGroup]="purchaseOrderForm">
                            <div class="custom-form-group">
                                <label for="organizationId">Organization</label>
                                <select id="organizationId" formControlName="organizationId"
                                    class="custom-form-control">
                                    <option value="" disabled>Select an organization</option>
                                    <option *ngFor="let org of user?.organizations" [value]="org.id">{{ org.name }}
                                    </option>
                                </select>
                                <div *ngIf="organizationId.invalid && organizationId.touched" class="custom-error">
                                    Organization is required.
                                </div>
                            </div>

                            <div class="custom-form-group">
                                <label for="referenceId">Purchase Order Number</label>
                                <input id="referenceId" type="text" formControlName="referenceId"
                                    class="custom-form-control">
                                <div *ngIf="referenceId.invalid && referenceId.touched" class="custom-error">
                                    <div *ngIf="referenceId.errors?.required">Purchase Order Number is required.</div>
                                    <div *ngIf="referenceId.errors?.minlength">Purchase Order Number must be at least 3
                                        characters long.</div>
                                    <span *ngIf="purchaseOrderForm.get('referenceId').hasError('specialChar')">Purchase
                                        Order Number accept only alphanumeric and special characters.</span>
                                    <span *ngIf="purchaseOrderForm.get('referenceId').hasError('maxlength')">Purchase
                                        Order Number cannot exceed 25 characters.</span>
                                    <span *ngIf="purchaseOrderForm.get('referenceId').hasError('minLength')">Purchase
                                        Order Number required minimum 3 characters.</span>

                                </div>
                            </div>

                            <div class="custom-form-group">
                                <label for="attachment">Attachments</label>
                                <input accept=".jpg,.jpeg,.png,.pdf" formControlName="attachment" id="attachment"
                                    type="file" class="custom-form-control" (change)="onFileSelect($event)" multiple>

                                <div *ngIf="attachment.invalid && attachment.touched" class="custom-error">
                                    At least one attachment is required.
                                </div>

                                <!-- Display Selected Files -->
                                <div class="file-list" *ngIf="uploadedPO.length > 0">
                                    <div *ngFor="let file of uploadedPO; let i = index" class="file-item">
                                        <span>{{ trimFileName(file.name, 15) }} ({{ file.size / 1000 }} KB)</span>
                                        <!-- <button type="button" class="custom-btn remove-btn" (click)="removeFile(i)">Remove</button> -->
                                        <svg (click)="removeFile(i)" xmlns="http://www.w3.org/2000/svg" width="60"
                                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M4 7l16 0" />
                                            <path d="M10 11l0 6" />
                                            <path d="M14 11l0 6" />
                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <small class="po-note" style="font-style: italic; font-weight: lighter;">
                                Please ensure Purchase Order is on an organization letterhead and signed by an officer.
                            </small>
                            <div style="display: flex; justify-content: center;">
                                <button
                                    style="background-color: #19a05d; color: white; border: #19a05d; border-radius: 4px; width: 12rem; height: 3rem; margin: 1rem;"
                                    (click)="paymentMethod=2;payByPo()" type="submit">
                                    Pay
                                </button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="paymentTypes" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5>Payment Methods</h5>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">

                <button (click)="openCheckoutScreen()" type="button"
                    style="height: 35px;width:100%; background-color: #00263f;  color: white; border: #00263f;"
                    class="add-product-button">
                    Pay with Credit Card
                </button><br /><br /><br />
                <button (click)="payWithPo()" type="button"
                    style="height: 35px;width:100%; background-color: #00263f;  color: white; border: #00263f;"
                    class="add-product-button">
                    Pay with Purchase Order
                </button>
            </div>
        </div>
    </div>
</div>